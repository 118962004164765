<template>
  <div
    ref="parent"
    class="d-flex p-consult white--bg relative"
    data-test="consultation-modal"
    :class="{ 'n-layout': tariff.provider.slug === 'mts' }"
    :style="{
      '--mainColor': color && color.main,
      '--font': color.font,
      '--font2': color.font2,
      '--font3': color.font3,
      '--consult': color.consult,
      '--price': color.price,
    }"
  >
    <div
      class="p-consult__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close.svg"
        alt="close"
      >
    </div>
    <div class="d-flex flex-column flex-grow">
      <p
        v-if="tariff"
        class="p-consult__overtitle relative title-5 gray-dark--text"
      >
        тариф «{{ tariff.name }}»
      </p>

      <template
        v-if="!segmentationActive"
      >
        <p class="p-consult__title headline-2">
          Получить консультацию
        </p>
        <p class="p-consult__subtitle title-2">
          Расскажем про тариф или подберем лучше, с учетом ваших пожеланий
        </p>
        <div class="p-consult__switch d-flex">
          <div class="p-consult__switch-wrap">
            <button
              class="p-consult__switch-btn title-5 text-no-wrap"
              :class="{
                'active': tab === 'new' && !segmentationActive,
                'no-pointer-events': segmentationActive,
              }"
              @click="
                tab = 'new';
                changeTariffType = '';
              "
            >
              Новое подключение
            </button>
            <button
              class="p-consult__switch-btn title-5 text-no-wrap"
              :class="{
                'active': tab === 'old' || segmentationActive,
                'no-pointer-events': segmentationActive,
              }"
              @click="tab = 'old'"
            >
              Смена тарифа
            </button>
          </div>
        </div>
      </template>
      <template
        v-else
      >
        <p class="p-consult__title headline-2">
          Вы являетесь действующим абонентом {{ partner.provider.name }}
        </p>
        <p class="p-consult__subtitle title-2">
          Мы не сможем ответить на вопросы по действующему подключению или
          сменить ваш текущий тариф.
        </p>
      </template>
      <template
        v-if="(tab === 'new' && !segmentationActive) || (changeTariffType === 'add' && partner?.provider?.id === 1)"
      >
        <form
          action="#"
          autocomplete="off"
          @submit.prevent="submit"
        >
          <span class="p-consult__label gray-dark--text title-5">Введите телефон</span>
          <VPhoneInput
            ref="dPhone"
            v-model:value="phoneNumber"
            class="w-100 q-modal__section"
            tabindex="1"
            @validate="checkPhoneValidation($event)"
          />
          <input
            class="p-consult__fakesubmit"
            type="submit"
          >
          <LazyVMenu
            content-class="white--bg"
            @update:model-value="showActiveSortList"
          >
            <template #activator="{ props }">
              <span
                v-bind="props"
                class="p-consult__menu pointer d-flex align-center gray-dark--text subhead-3"
              >
                Перезвоним {{ timeToCall }}
                <img
                  :class="{ rotate: props['aria-expanded'] === 'true' }"
                  style="transition: transform 0.24s; width: 20px; height: 20px;"
                  loading="lazy"
                  src="@/assets/images/svg/arrow-expand.svg"
                  alt="arrow"
                >
              </span>
            </template>
            <LazyVList elevation="4">
              <LazyVListItem
                :value="timeNow"
                :disabled="timeNow.text === timeToCall"
                @click="setTimeToCall(timeNow)"
              >
                <span class="subhead-3">{{ timeNow.text }}</span>
              </LazyVListItem>
              <LazyVListItem
                v-for="item in timeList"
                :key="item.val"
                :disabled="item.text === timeToCall"
                @click="setTimeToCall(item)"
              >
                <span class="subhead-3">{{ item.text }}</span>
              </LazyVListItem>
            </LazyVList>
          </LazyVMenu>

          <d-btn
            :loading="loading"
            :disabled="!isValidPhone"
            class="w-100 p-consult__submit"
            color="primary"
            size="medium"
            @click.prevent="submit"
          >
            Отправить
          </d-btn>
        </form>

        <p class="subhead-6 gray-dark--text">
          Отправляя, вы соглашаетесь с
          <button
            class="secondary-dark--text"
            @click="goAway('/legal/privacy/')"
          >
            политикой обработки данных
          </button>
        </p>
      </template>
      <div
        v-else
        class="p-consult__old"
      >
        <template v-if="!changeTariffType && !segmentationActive">
          <div class="p-consult__ch-type d-flex flex-column">
            <p
              class="p-consult__chbx"
              :class="{ active: chType === 'add' }"
              @click="chType = 'add'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'add' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Подключение домашнего интернета, ТВ или мобильной связи в мой
              тариф
            </p>
            <p
              style="margin-top: 8px"
              class="p-consult__chbx"
              :class="{ active: chType === 'change' }"
              @click="chType = 'change'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Смена тарифа с сохранением набора услуг
            </p>
          </div>
          <d-btn
            :disabled="!chType"
            class="w-100 p-consult__submit"
            color="primary"
            size="medium"
            style="margin-top: 16px; margin-bottom: 0"
            @click="changeType"
          >
            Далее
          </d-btn>
        </template>

        <template v-else-if="changeTariffType === 'change' || segmentationActive">
          <p class="p-consult__old-title title-5">
            Для смены тарифа необходимо позвонить по номеру
          </p>
          <a
            v-if="phone"
            class="p-consult__old-phone secondary-dark--text headline-3"
            :href="'tel:' + phone.call_number"
            @click.prevent="callClick(phone.call_number, 'SHOW_SUPPORT')"
          >{{ phone.display_number }}</a>
          <span class="subhead-6 d-block">Звонок бесплатный по РФ</span>
          <p
            v-if="partner?.provider?.lk_url"
            class="p-consult__old-lk title-5"
          >
            или узнать информацию в
            <a
              class="secondary-dark--text"
              :href="partner.provider.lk_url"
              target="_blank"
            >личном кабинете</a>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { createInteraction } from '~/composible/createInteraction'

import { goAway } from '~/helpers'
import { callTimeComposible } from '~/composible/callTimeComposible'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  tariff: {
    type: Object,
    required: true,
  },
  payments: {
    type: Array,
    default: undefined,
  },
})

const ctx = useNuxtApp()
const $gtm = useGtm()
const mainStore = useMainStore()
const cityStore = useCities()
const parent = ref()
const phoneNumber = ref('')
const chType = ref('')
const changeTariffType = ref('')
const tab = ref('new')
const phone = computed(() => mainStore.getCallNumber?.support)
const loading = ref(false)
const isValidPhone = ref(false)
const dPhone = ref()
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const partner = computed(() => mainStore.getDomainConfig)
const segmentation = computed(() => mainStore.getSegmentation)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const color = computed(() => {
  if (partner.value?.provider.config?.color)
    return partner.value?.provider.config?.color
})
const $router = useRouter()
const tariffsStore = useTariffs()
const houseUrl = computed(() => cityStore.getHouseUrl)

const changeType = () => {
  changeTariffType.value = chType.value
  if (chType.value === 'change' || partner.value.provider.id !== 1) {
    if (!interactionsSended.value) {
      mainStore.$patch({
        interactionsSended: true,
      })
      const tariffS = {
        tariff_id: props.tariff?.id,
        fake_mobile: props.tariff?.markers?.fakeMobile,
        fake: props.tariff?.markers?.fake,
        tariff_name: props.tariff?.name,
        provider_id: props.tariff?.provider.id,
      }
      cI(
        true,
        undefined,
        tariffS,
        { request_type: 'SHOW_SUPPORT' },
        undefined,
        undefined,
      )
    }
    mainStore.$patch({
      oldClient: segmentation.value,
    })
    oldClientCookie.value = segmentation.value
  }
  else {
    nextTick(() => {
      if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
    })
  }
}

const {
  timeList,
  timeNow,
  timeToCall,
  callTime,
  setTimeToCall,
  showActiveSortList,
} = callTimeComposible()

const { cI } = createInteraction()

const submit = async () => {
  if (!isValidPhone.value) {
    dPhone.value.onBlur()
    return
  }
  loading.value = true

  const tariffS = {
    tariff_id: props.tariff?.id,
    fake: props.tariff?.markers?.fake,
    fake_mobile: props.tariff?.markers?.fakeMobile,
    tariff_name: props.tariff?.name,
    provider_id: props.tariff?.provider.id,
    device_payment_ids: props.payments,
  }
  const lead = {
    phone: '+7' + phoneNumber.value.replace(/[\-(\s)]/g, ''),
    call_time: callTime.value,
  }

  const meta = {
    request_name: 'tariff-consult',
  }

  const ciResp = await cI(!!houseUrl.value, lead, tariffS, meta, undefined)

  $gtm.trackEvent({
    event: 'trackEvent',
    category: 'form',
    action: 'success',
    label: 'tariff-consult',
    tariffData: props.tariff,
  })

  tariffsStore.$patch({
    orderId: ciResp,
    interactionId: ciResp,
    timeToCall: timeToCall.value,
  })
  loading.value = false

  nextTick(() => {
    $router.push({ path: '/complete/' })
  })
}

const checkPhoneValidation = (event) => {
  isValidPhone.value = event
}

const callClick = (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'phonelink',
      action: 'support',
    })
    mainStore.$patch({
      interactionsSended: true,
    })
    const tariffS = {
      tariff_id: props.tariff?.id,
      fake_mobile: props.tariff?.markers?.fakeMobile,
      fake: props.tariff?.markers?.fake,
      tariff_name: props.tariff?.name,
      provider_id: props.tariff?.provider.id,
    }
    cI(true, undefined, tariffS, { request_type: type }, undefined, undefined)
  }
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onBeforeMount(async () => {
  console.log('OrderConsult modal opened')
  $gtm.trackEvent({
    event: 'trackEvent',
    category: 'form',
    action: 'open',
    label: 'tariff-consult',
    tariffData: props.tariff,
  })
  await mainStore.fetchCallSlots()
  timeToCall.value = timeNow.value.text
})

onMounted(() => {
  if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})

watch(
  () => tab.value,
  () => {
    if (tab.value === 'new') {
      nextTick(() => {
        if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
      })
    }
  },
)
</script>

<style scoped lang="scss">
.p-consult {
  width: 500px;
  padding: 40px 40px 16px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 16px 16px 0 0;
    padding: 16px 16px 12px;
    height: 100%;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 24px 16px;
  }
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-darker);
    z-index: 1;
    img {
      opacity: .7;
      transition: opacity .24s;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &:deep(.p-input), &:deep(.t-input) {
    background-color: color(gray-light-3);
  }
  &__menu {
    margin-right: auto;
    margin-bottom: 16px;
  }

  &.n-layout {
    &:deep(.el-input__wrapper),
    &:deep(.p-input),
    &:deep(.t-input),
    &:deep(.p-input input),
    &:deep(.t-input input),
    &:deep(.p-consult__submit),
    &:deep(.p-consult__switch-btn),
    &:deep(.p-consult__switch-wrap) {
      border-radius: 8px;
    }
  }
  &__overtitle {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
      margin-right: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 12px;
    }
  }
  &__title {
    margin-bottom: 8px;
  }
  &__subtitle {
    margin-bottom: 20px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 20px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 16px;
    }
  }

  &__switch {
    margin-right: -8px;
    &-wrap {
      padding: 3px;
      border-radius: 28px;
      border: 1px solid color(gray-darker);
      background: color(gray-p);
      margin-right: auto;
      margin-bottom: 24px;
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 16px;
      }
    }

    &-btn {
      padding: 8px 15px;
      border: 1px solid transparent;
      border-radius: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding: 8px 11px;
      }
      &.active {
        background: color(white);
        border: 1px solid var(--mainColor);
      }
    }
  }

  &__old {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 16px;
    }
    &-phone {
      transition: color 0.24s;
      &:hover {
        color: var(--mainColor);
      }
    }
    &-lk {
      margin-top: 16px;
      a {
        transition: color 0.24s;
        &:hover {
          color: var(--mainColor);
        }
      }
    }
  }

  &__chbx {
    flex-shrink: 0;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
    color: color(black);
    border: 1px solid color(gray-darker);
    border-radius: 12px;
    padding: 16px 16px 16px 48px;
    & > span {
      position: absolute;
      border-radius: 50%;
      left: 16px;
    }

    &.active {
      & > span {
        background: var(--mainColor);
        color: var(--font2);
      }
    }
    &:not(.active) {
      & > span {
        border: 1px solid color(gray-darker);
      }
    }
  }

  &__fakesubmit {
    max-height: 0 !important;
    overflow: hidden !important;
    opacity: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
  }

  &__submit {
    margin-bottom: 40px;
    height: 56px;
    box-shadow: none !important;
    &:not(:disabled) {
      background: var(--mainColor) !important;
      color: var(--font2) !important;
      &:hover {
        background: var(--mainColor) !important;
      }
    }
    &:focus,
    &:active {
      background: var(--mainColor) !important;
    }
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    span {
      margin-right: auto;
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    flex-wrap: wrap;
    width: 100%;
  }
}
</style>
